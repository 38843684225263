// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-home-js": () => import("../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-templates-blog-js": () => import("../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-category-js": () => import("../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-armazenamento-remoto-js": () => import("../src/pages/armazenamento-remoto.js" /* webpackChunkName: "component---src-pages-armazenamento-remoto-js" */),
  "component---src-pages-carreiras-js": () => import("../src/pages/carreiras.js" /* webpackChunkName: "component---src-pages-carreiras-js" */),
  "component---src-pages-certificados-ssl-js": () => import("../src/pages/certificados-ssl.js" /* webpackChunkName: "component---src-pages-certificados-ssl-js" */),
  "component---src-pages-clientes-js": () => import("../src/pages/clientes.js" /* webpackChunkName: "component---src-pages-clientes-js" */),
  "component---src-pages-cloud-server-js": () => import("../src/pages/cloud-server.js" /* webpackChunkName: "component---src-pages-cloud-server-js" */),
  "component---src-pages-colocation-js": () => import("../src/pages/colocation.js" /* webpackChunkName: "component---src-pages-colocation-js" */),
  "component---src-pages-contatos-js": () => import("../src/pages/contatos.js" /* webpackChunkName: "component---src-pages-contatos-js" */),
  "component---src-pages-contrato-js": () => import("../src/pages/contrato.js" /* webpackChunkName: "component---src-pages-contrato-js" */),
  "component---src-pages-core-login-js": () => import("../src/pages/core-login.js" /* webpackChunkName: "component---src-pages-core-login-js" */),
  "component---src-pages-cultura-js": () => import("../src/pages/cultura.js" /* webpackChunkName: "component---src-pages-cultura-js" */),
  "component---src-pages-data-center-nordeste-js": () => import("../src/pages/data-center-nordeste.js" /* webpackChunkName: "component---src-pages-data-center-nordeste-js" */),
  "component---src-pages-data-center-orlando-js": () => import("../src/pages/data-center-orlando.js" /* webpackChunkName: "component---src-pages-data-center-orlando-js" */),
  "component---src-pages-data-center-sao-paulo-js": () => import("../src/pages/data-center-sao-paulo.js" /* webpackChunkName: "component---src-pages-data-center-sao-paulo-js" */),
  "component---src-pages-disaster-recovery-js": () => import("../src/pages/disaster-recovery.js" /* webpackChunkName: "component---src-pages-disaster-recovery-js" */),
  "component---src-pages-dns-tools-js": () => import("../src/pages/dns-tools.js" /* webpackChunkName: "component---src-pages-dns-tools-js" */),
  "component---src-pages-dominios-js": () => import("../src/pages/dominios.js" /* webpackChunkName: "component---src-pages-dominios-js" */),
  "component---src-pages-estrutura-js": () => import("../src/pages/estrutura.js" /* webpackChunkName: "component---src-pages-estrutura-js" */),
  "component---src-pages-ferramentas-js": () => import("../src/pages/ferramentas.js" /* webpackChunkName: "component---src-pages-ferramentas-js" */),
  "component---src-pages-gerenciamento-dedicado-js": () => import("../src/pages/gerenciamento-dedicado.js" /* webpackChunkName: "component---src-pages-gerenciamento-dedicado-js" */),
  "component---src-pages-hospedagem-de-sites-js": () => import("../src/pages/hospedagem-de-sites.js" /* webpackChunkName: "component---src-pages-hospedagem-de-sites-js" */),
  "component---src-pages-internal-proxy-js": () => import("../src/pages/internal-proxy.js" /* webpackChunkName: "component---src-pages-internal-proxy-js" */),
  "component---src-pages-licencas-js": () => import("../src/pages/licencas.js" /* webpackChunkName: "component---src-pages-licencas-js" */),
  "component---src-pages-model-page-js": () => import("../src/pages/model-page.js" /* webpackChunkName: "component---src-pages-model-page-js" */),
  "component---src-pages-nossa-jornada-js": () => import("../src/pages/nossa-jornada.js" /* webpackChunkName: "component---src-pages-nossa-jornada-js" */),
  "component---src-pages-nosso-atendimento-js": () => import("../src/pages/nosso-atendimento.js" /* webpackChunkName: "component---src-pages-nosso-atendimento-js" */),
  "component---src-pages-nossos-produtos-js": () => import("../src/pages/nossos-produtos.js" /* webpackChunkName: "component---src-pages-nossos-produtos-js" */),
  "component---src-pages-outros-produtos-js": () => import("../src/pages/outros-produtos.js" /* webpackChunkName: "component---src-pages-outros-produtos-js" */),
  "component---src-pages-pedido-de-certificado-ssl-js": () => import("../src/pages/pedido-de-certificado-ssl.js" /* webpackChunkName: "component---src-pages-pedido-de-certificado-ssl-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-por-que-a-hostdime-js": () => import("../src/pages/por-que-a-hostdime.js" /* webpackChunkName: "component---src-pages-por-que-a-hostdime-js" */),
  "component---src-pages-precos-cloud-server-js": () => import("../src/pages/precos-cloud-server.js" /* webpackChunkName: "component---src-pages-precos-cloud-server-js" */),
  "component---src-pages-premiacoes-e-certificados-js": () => import("../src/pages/premiacoes-e-certificados.js" /* webpackChunkName: "component---src-pages-premiacoes-e-certificados-js" */),
  "component---src-pages-press-kit-js": () => import("../src/pages/press-kit.js" /* webpackChunkName: "component---src-pages-press-kit-js" */),
  "component---src-pages-protecao-ddos-premium-js": () => import("../src/pages/protecao-ddos-premium.js" /* webpackChunkName: "component---src-pages-protecao-ddos-premium-js" */),
  "component---src-pages-protecao-ddos-js": () => import("../src/pages/protecao-ddos.js" /* webpackChunkName: "component---src-pages-protecao-ddos-js" */),
  "component---src-pages-protection-ddos-cloud-js": () => import("../src/pages/protection-ddos-cloud.js" /* webpackChunkName: "component---src-pages-protection-ddos-cloud-js" */),
  "component---src-pages-register-core-js": () => import("../src/pages/register-core.js" /* webpackChunkName: "component---src-pages-register-core-js" */),
  "component---src-pages-resumo-do-pedido-js": () => import("../src/pages/resumo-do-pedido.js" /* webpackChunkName: "component---src-pages-resumo-do-pedido-js" */),
  "component---src-pages-servidores-dedicados-nordeste-js": () => import("../src/pages/servidores-dedicados-nordeste.js" /* webpackChunkName: "component---src-pages-servidores-dedicados-nordeste-js" */),
  "component---src-pages-servidores-dedicados-orlando-js": () => import("../src/pages/servidores-dedicados-orlando.js" /* webpackChunkName: "component---src-pages-servidores-dedicados-orlando-js" */),
  "component---src-pages-servidores-dedicados-outlet-js": () => import("../src/pages/servidores-dedicados-outlet.js" /* webpackChunkName: "component---src-pages-servidores-dedicados-outlet-js" */),
  "component---src-pages-servidores-dedicados-sao-paulo-js": () => import("../src/pages/servidores-dedicados-sao-paulo.js" /* webpackChunkName: "component---src-pages-servidores-dedicados-sao-paulo-js" */),
  "component---src-pages-servidores-dedicados-js": () => import("../src/pages/servidores-dedicados.js" /* webpackChunkName: "component---src-pages-servidores-dedicados-js" */),
  "component---src-pages-sobre-js": () => import("../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-suporte-js": () => import("../src/pages/suporte.js" /* webpackChunkName: "component---src-pages-suporte-js" */),
  "component---src-pages-termos-de-servico-js": () => import("../src/pages/termos-de-servico.js" /* webpackChunkName: "component---src-pages-termos-de-servico-js" */),
  "component---src-pages-vagas-abertas-js": () => import("../src/pages/vagas-abertas.js" /* webpackChunkName: "component---src-pages-vagas-abertas-js" */)
}

