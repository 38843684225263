module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"hostdime-brasil-site","short_name":"hostdime-BR","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-wordpress-inline-images/gatsby-browser.js'),
      options: {"plugins":[],"baseUrl":"hostdime.com.br/wp","protocol":"https"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-3466240-12"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"688105234873271"},
    },{
      plugin: require('../node_modules/gatsby-plugin-drift/gatsby-browser.js'),
      options: {"plugins":[],"appId":"tmpbxfrrn2rz"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
